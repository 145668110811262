// CreateBookkeeping.js
import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ActionButtons from "../../components/Bookkeeping/CreateBookkeeping/ActionButtons";
import DeleteModal from "../../components/Bookkeeping/CreateBookkeeping/DeleteModal";
import RecordForm from "../../components/Bookkeeping/CreateBookkeeping/RecordForm";

Modal.setAppElement("#root");

const CreateBookkeeping = () => {
  const { monthYearId } = useParams();
  const [records, setRecords] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(null);
  const [formData, setFormData] = useState({
    outlet_name: "",
    item_name: "",
    unit_price: "",
    quantity_sold: "",

  });
  const [monthYearOptions, setMonthYearOptions] = useState([]);
  const email = localStorage.getItem("userEmail");
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  useEffect(() => {
    fetchRecords();
    fetchMonthYearOptions();
  }, []);

  const fetchRecords = async () => {
    setLoadingData(true);
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/records/monthyear/${monthYearId}/${email}`
      );
      setRecords(response.data.data);
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const fetchMonthYearOptions = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/monthyear/${email}`
      );
      setMonthYearOptions(response.data.monthyear);
    } catch (error) {
      console.error("Error fetching monthyear options:", error);
    }
  };

  const handleCreate = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setFormData({
      outlet_name: "",
      item_name: "",
      unit_price: "",
      quantity_sold: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "unit_price" ||
        name === "quantity_sold"
          ? parseFloat(value)
          : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(
        `${apiUrl}/api/v1/records/${email}`,
        { ...formData, month_year_id: parseInt(monthYearId) }
      );
      toast.success("Data created successfully!");
      fetchRecords();
      handleModalClose();
    } catch (error) {
      console.error("Error creating record:", error);
      toast.error("Failed to create data.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (deleteRecordId) {
      setIsLoading(true);
      try {
        await axios.delete(
          `${apiUrl}/api/v1/records/${deleteRecordId}`
        );
        toast.success("Data deleted successfully!");
        fetchRecords();
        closeDeleteModal();
      } catch (error) {
        console.error("Error deleting record:", error);
        toast.error("Failed to delete data.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const openDeleteModal = (recordId) => {
    setDeleteRecordId(recordId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteRecordId(null);
  };

  const handleDownloadPDF = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/records/download/${email}/${monthYearId}`,
        {
          responseType: "blob", // Important for handling binary data
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "records.pdf"); // Adjust filename as needed
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error("Failed to download PDF.");
    }
  };

  return (
    <div className="container mx-auto py-6">
      <h1 className="text-2xl font-bold mb-6">Create Bookkeeping</h1>
      <ActionButtons
        handleCreate={handleCreate}
        handleDownloadPDF={handleDownloadPDF}
      />

      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Records</h2>
        <div className="overflow-x-auto">
          {" "}
          {/* This makes the table horizontally scrollable on small screens */}
          <table className="min-w-full bg-white border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-2 px-4 border-b">Outlet Name</th>
                <th className="py-2 px-4 border-b">Item Name</th>
                <th className="py-2 px-4 border-b">Unit Price</th>
                <th className="py-2 px-4 border-b">Quantity Sold</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {records.length > 0 ? (
                records.map((record) => (
                  <tr
                    key={record.id}
                    className="hover:bg-gray-50 transition duration-200 text-center"
                  >
                    <td className="py-2 px-4 border-b">{record.outlet_name}</td>
                    <td className="py-2 px-4 border-b">{record.item_name}</td>
                    <td className="py-2 px-4 border-b">{record.unit_price}</td>
                    <td className="py-2 px-4 border-b">
                      {record.quantity_sold}
                    </td>
                    <td className="py-2 px-4 border-b flex space-x-2">
                      <button
                        onClick={() => openDeleteModal(record.id)}
                        className="bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700 transition duration-200"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="py-2 px-4 text-center">
                    No records found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 md:w-1/3">
          <h2 className="text-xl font-bold mb-4">Add New Record</h2>
          <RecordForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
            handleModalClose={handleModalClose}
          />
        </div>
      </Modal>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        closeDeleteModal={closeDeleteModal}
        handleDelete={handleDelete}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CreateBookkeeping;
