import { XMarkIcon } from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';

const ImageModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const imageUrl = "https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/modal_image_home/AI-in-Evryday.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9tb2RhbF9pbWFnZV9ob21lL0FJLWluLUV2cnlkYXkucG5nIiwiaWF0IjoxNzM4NjgzMTY1LCJleHAiOjE3NzAyMTkxNjV9.0khH9J4U6qqjCjxWSYU9Q4WXQDNTBoO8q5SwVHBrDIY";

  return (
    <motion.div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: isOpen ? 1 : 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <motion.div
        className="relative p-6"
        initial={{ scale: 0.9 }}
        animate={{ scale: isOpen ? 1 : 0.9 }}
        exit={{ scale: 0.9 }}
        transition={{ duration: 0.3 }}
      >
        <button
          className="absolute top-4 right-4 text-white p-2 bg-gray-800 rounded-full hover:bg-gray-700 focus:outline-none"
          onClick={onClose}
        >
          <XMarkIcon className="h-6 w-6" />
        </button>
        <img
          src={imageUrl}
          alt="Preview"
          className="max-w-full max-h-[80vh] w-auto h-auto rounded-md"
        />
      </motion.div>
    </motion.div>
  );
};

export default ImageModal;
