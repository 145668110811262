import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import SkeletonLoader from "../YoutubeTrending/SkeletonLoader";

const LifestyleNews = () => {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const userEmail = localStorage.getItem("userEmail");
        const response = await fetch("https://convogenius.cloud/api/v1/news/lifestyle", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: userEmail }),
        });
    
        const data = await response.json();
    
        if (data.status === "success" && Array.isArray(data.data)) {
          setNews(data.data.slice(0, 4));
        } else {
          console.error("Unexpected API response format:", data);
        }
      } catch (error) {
        console.error("Error fetching lifestyle news:", error);
      } finally {
        setIsLoading(false);
      }
    };
    

    fetchNews();
  }, []);

  return (
    <div className="p-7 w-full transition duration-300">
      {/* Heading dan Tombol */}
      <h3 className="lg:text-2xl text-xl text-gray-800 font-semibold mb-4">
        News Lifestyle
      </h3>
      <div className="flex justify-start mb-4">
        <a href="/all-news-lifestyle">
          <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 flex items-center space-x-2">
            <span>All News Lifestyle</span>
            <ArrowRightIcon className="h-5 w-5" />
          </button>
        </a>
      </div>

      {/* Skeleton Loader saat loading */}
      {isLoading ? (
        <SkeletonLoader count={4} />
      ) : (
        <div className="flex gap-4 overflow-x-auto md:overflow-x-hidden md:flex-wrap md:justify-center whitespace-nowrap">
          {news.map((article, index) => (
            <div
              key={index}
              className="text-gray-800 hover:text-white bg-white border border-blue-600 p-4 rounded-lg shadow-lg flex flex-col items-center w-[16rem] flex-shrink-0 transition duration-300 hover:bg-blue-800 cursor-pointer"
              onClick={() => window.open(article.link, "_blank")}
            >
              <img
                src={article.thumbnail}
                alt={article.title}
                className="w-full h-40 object-cover rounded-lg mb-4"
              />
              <h4
                className="font-semibold mb-1 text-sm md:text-base text-center line-clamp-2 overflow-hidden text-ellipsis w-full"
                title={article.title} // Tooltip jika user hover
              >
                {article.title}
              </h4>
              <p className="text-gray-500 text-xs">
                {new Date(article.pubDate).toLocaleDateString("id-ID", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LifestyleNews;
