import { faArrowRight, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const MusicPage = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [responseStarted, setResponseStarted] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [showPrompts, setShowPrompts] = useState(true);
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");
    setUserEmail(userEmail);

    fetchUserInfo(userEmail);
  }, []);

  const fetchUserInfo = (email) => {
    const token = localStorage.getItem("authToken");
    const endpoint = `${apiUrl}/api/v1/dashboard`;

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Email": email,
      },
      body: JSON.stringify({ email })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUserInfo(data.user);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    const message = {
      id: messages.length + 1,
      text: newMessage,
      type: "text",
      sender: "user",
      timestamp: new Date().toISOString(),
    };

    setMessages([...messages, message]);
    setNewMessage("");
    setLoading(true);
    setTimeElapsed(0);
    setResponseStarted(false);
    setShowPrompts(false);

    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 100);

    queryMusic({ inputs: newMessage })
      .then((audioUrl) => {
        const responseMessage = {
          id: messages.length + 2,
          audio: audioUrl,
          type: "audio",
          sender: "bot",
          timestamp: new Date().toISOString(),
        };
        setMessages((prevMessages) => [...prevMessages, responseMessage]);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        clearInterval(timer);
        setLoading(false);
      });
  };

  const queryMusic = async (data) => {
    const response = await fetch(
      `${apiUrl}/api/v1/generate-music/${userEmail}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const result = await response.blob();
    return URL.createObjectURL(result);
  };

  const handlePromptClick = (prompt) => {
    const message = {
      id: messages.length + 1,
      text: prompt,
      type: "text",
      sender: "user",
      timestamp: new Date().toISOString(),
    };

    setMessages([...messages, message]);
    setLoading(true);
    setTimeElapsed(0);
    setResponseStarted(false);
    setShowPrompts(false);

    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 100);

    queryMusic({ inputs: prompt })
      .then((audioUrl) => {
        const responseMessage = {
          id: messages.length + 2,
          audio: audioUrl,
          type: "audio",
          sender: "bot",
          timestamp: new Date().toISOString(),
        };
        setMessages((prevMessages) => [...prevMessages, responseMessage]);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        clearInterval(timer);
        setLoading(false);
      });
  };

  const handleClearMessage = (id) => {
    setMessages(messages.filter((message) => message.id !== id));
  };

  return (
    <div className="flex flex-col h-screen bg-white text-gray-800">
      <Helmet>
        <title>Branding Music With AI | {userEmail}</title>
      </Helmet>
      <header className="bg-white text-gray-800 py-2 px-4 flex justify-between items-center">
        <a href="/image-ai">
          <button className="flex items-center px-4 py-2  text-gray-800 hover:text-gray-500 rounded transition duration-200 text-sm">
            <ArrowLeftIcon className="h-5 w-5 mr-2 text-sm" />
            Back To Image
          </button>
        </a>
        <a href="/dashboard">
          <div className="flex items-center">
            <p className="text-sm mr-2 text-gray-800 hover:text-gray-500">
              Back To Dashboard
            </p>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="text-gray-800 hover:text-gray-200"
            />
          </div>
        </a>
      </header>
      {showPrompts && (
        <div className="flex flex-col items-center mt-40 bg-white">
          <img src="images/logo.png" alt="Logo" className="mb-4 w-20 h-20" />
          <div className="grid grid-cols-2 gap-4">
            <PromptCard
              text="Generate a classical piece"
              onClick={() => handlePromptClick("Generate a classical piece")}
            />
            <PromptCard
              text="Create a jazz tune"
              onClick={() => handlePromptClick("Create a jazz tune")}
            />
            <PromptCard
              text="Compose a rock song"
              onClick={() => handlePromptClick("Compose a rock song")}
            />
            <PromptCard
              text="Make a hip-hop beat"
              onClick={() => handlePromptClick("Make a hip-hop beat")}
            />
          </div>
        </div>
      )}
      <main className="flex-1 p-4 overflow-y-auto">
        {messages.map((message) => (
          <Message
            key={message.id}
            message={message}
            onClear={() => handleClearMessage(message.id)}
          />
        ))}
        {loading && !responseStarted && <SkeletonLoader />}
      </main>
      <form
        onSubmit={handleSendMessage}
        className="flex justify-center items-center py-2 px-4 lg:py-2"
      >
        <div className="flex items-center max-w-2xl w-full">
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            rows={1} // Starting with a single row; it will expand as needed
            className="flex-1 px-4 py-2 bg-white rounded-lg focus:outline-none focus:bg-gray-200 text-gray-800 border border-gray-300 resize-none overflow-y-auto shadow-md"
            style={{
              maxHeight: "200px", // Optional: max height limit for scrolling
              paddingRight: "2.5rem", // Adjust for aesthetics
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevent the default new line behavior
                handleSendMessage(e); // Call the function to send the message
              }
            }}
          />
          <button
            type="submit"
            className="ml-2 bg-blue-600 text-white p-3 rounded-full hover:bg-blue-700 focus:bg-blue-700 focus:outline-none"
          >
            {loading ? (
              <span className="text-sm">{(timeElapsed / 10).toFixed(1)}s</span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
              </svg>
            )}
          </button>
        </div>
      </form>
      {error && <p className="text-blue-500 mt-2 ml-4">{error}</p>}
    </div>
  );
};

const SkeletonLoader = () => {
  return (
    <div className="flex justify-start mb-2">
      <div className="max-w-xs bg-gray-200 p-3 rounded-lg shadow-md">
        <div className="flex space-x-2">
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-400"></div>
        </div>
      </div>
    </div>
  );
};

const Message = ({ message, onClear }) => {
  const { text, audio, sender, timestamp } = message;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div
      className={`flex ${sender === "user" ? "justify-end" : "justify-start"
        } mb-2`}
    >
      <div className={`max-w-md ${sender === "user" ? "ml-auto" : "mr-auto"}`}>
        {audio ? (
          <audio controls className="rounded-lg">
            <source src={audio} type="audio/wav" />
            Your browser does not support the audio element.
          </audio>
        ) : (
          <div
            className={`rounded-lg ${sender === "user"
                ? "bg-blue-600 text-white"
                : "bg-gray-300 text-gray-900"
              } py-2 px-4 break-words`}
          >
            {text}
          </div>
        )}
        <div className="text-xs text-gray-500 mt-1 flex justify-between items-center">
          <span>{formatDate(timestamp)}</span>
          <button
            onClick={onClear}
            className="ml-2 text-red-700 hover:text-red-800 focus:outline-none"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
      </div>
    </div>
  );
};

const PromptCard = ({ text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-white border border-blue-600 text-gray-800 hover:text-white p-4  mr-2 ml-2  rounded-lg shadow-md cursor-pointer hover:bg-blue-700 transition duration-300"
    >
      {text}
    </div>
  );
};

export default MusicPage;
