// pages/AllTrendingYouTube.jsx
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import SkeletonLoader from "../../components/TrendingYoutube/SkeletonLoader";
import TrendingVideoCard from "../../components/TrendingYoutube/TrendingVideoCard";
import VideoPopup from "../../components/TrendingYoutube/VideoPopup";

export default function AllTrendingYouTube() {
  const [youtubeTrending, setYoutubeTrending] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isFullDescription, setIsFullDescription] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  const observer = useRef();

  useEffect(() => {
    fetchTrendingVideos(page);
  }, [page]);

  const fetchTrendingVideos = async (currentPage) => {
    try {
      setIsFetchingMore(true);
      const response = await axios.get(
        `${apiUrl}/api/v1/youtube/trending-50`
      );

      const videos = response.data.data;
      const startIndex = (currentPage - 1) * 10;
      const newVideos = videos.slice(startIndex, startIndex + 10);

      setYoutubeTrending((prev) => [...prev, ...newVideos]);
      setHasMore(newVideos.length > 0);
    } catch (error) {
      console.error("Error fetching trending videos:", error);
    } finally {
      setLoading(false);
      setIsFetchingMore(false);
    }
  };

  const lastVideoRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleVideoClick = (video) => setSelectedVideo(video);
  const closePopup = () => {
    setSelectedVideo(null);
    setIsFullDescription(false);
  };

  return (
    <div className="p-6 lg:mb-0 mb-20">
      <Helmet>
        <title>Convogenius | All Trending YouTube Videos</title>
      </Helmet>

      <h2 className="lg:text-3xl text-xl text-gray-800 font-semibold mb-2 text-center">
        All Trending YouTube
      </h2>
      <p className="text-lg text-gray-600 text-center mb-2 mt-2">
        Top Trending to help innovate your product
      </p>

      <div className="p-6 rounded-lg w-full transition duration-300">
        <h3 className="lg:text-2xl text-xl text-gray-800 font-semibold mb-4">
          All Trending on YouTube
        </h3>

        {youtubeTrending.length === 0 && loading ? (
          <SkeletonLoader count={8} />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {youtubeTrending.map((item, index) => (
              <TrendingVideoCard
                key={index}
                video={item}
                index={index}
                onClick={handleVideoClick}
                lastVideoRef={
                  youtubeTrending.length === index + 1 ? lastVideoRef : null
                }
              />
            ))}
          </div>
        )}

        {isFetchingMore && (
          <div className="flex justify-center items-center mt-4">
            <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}
      </div>

      {selectedVideo && (
        <VideoPopup
          video={selectedVideo}
          closePopup={closePopup}
          isFullDescription={isFullDescription}
          setIsFullDescription={setIsFullDescription}
        />
      )}
    </div>
  );
}
