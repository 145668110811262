import { ArrowRightIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import SkeletonLoader from "./SkeletonLoader";
import TrendingCard from "./TrendingCard";
import VideoModal from "./VideoModal";

const YouTubeTrending = () => {
  const [youtubeTrending, setYoutubeTrending] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [error, setError] = useState(null);
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  const fetchYouTubeTrending = () => {
    const endpoint = `${apiUrl}/api/v1/youtube/trending`;

    fetch(endpoint)
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch trending videos");
        return response.json();
      })
      .then((result) => {
        if (result.status === 200 && Array.isArray(result.data)) {
          setYoutubeTrending(result.data.slice(0, 4)); // Display first 4 videos
        } else throw new Error("Unexpected response structure");
      })
      .catch((error) => setError(error.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchYouTubeTrending();
  }, []);

  const handleVideoClick = (video) => setSelectedVideo(video);
  const closeModal = () => setSelectedVideo(null);

  return (
    <div className="p-6 rounded-lg w-full transition duration-300">
      <h3 className="lg:text-2xl text-xl text-gray-800 font-semibold mb-4">
        Trending on YouTube
      </h3>
      <div className="flex justify-start mb-4">
        <a href="/all-trending-youtube">
          <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-700 transition duration-300 flex items-center space-x-2">
            <span>All See Trending</span>
            <ArrowRightIcon className="h-5 w-5" />
          </button>
        </a>
      </div>

      {loading ? (
        <SkeletonLoader count={4} />
      ) : youtubeTrending.length > 0 ? (
        <div className="flex overflow-x-auto md:overflow-x-hidden space-x-4 pb-2">
          {youtubeTrending.map((video, index) => (
            <TrendingCard
              key={index}
              video={video}
              onClick={() => handleVideoClick(video)}
            />
          ))}
        </div>
      ) : (
        <p className="text-white">No data available</p>
      )}

      {selectedVideo && (
        <VideoModal video={selectedVideo} onClose={closeModal} />
      )}
    </div>
  );
};

export default YouTubeTrending;
