import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserProfile from "../../components/Account/UserProfile";

const AccountPage = () => {
  const [email, setEmail] = useState(""); // Keep this if used in the helmet title

  useEffect(() => {
    const loggedInEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");
    const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
    const apiUrl = atob(encodedUrl);

    if (!loggedInEmail || !token) {
      toast.error("No logged in user found.");
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/api/v1/${loggedInEmail}/user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = await response.json();

        if (result.status === 200 && result.data) {
          const user = result.data;

          setEmail(user.Email); // This is used for setting the title

          // Further data processing can be added here if needed.
        } else {
          toast.error("User data not found.");
        }
      } catch (error) {
        toast.error("Error fetching user data.");
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-white min-h-screen flex flex-col items-center p-4">
      <Helmet>
        <title>Account | {email}</title>
      </Helmet>
      <ToastContainer />

      <>
        <UserProfile />
      </>
    </div>
  );
};

export default AccountPage;
