import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SkeletonCard from "../../components/SkeletonCard/SkeletonCard"; // Import SkeletonCard component
// import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";
const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const blogsPerPage = 12; // Blogs per page
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/blogs/get-blogs`
      );

      // Access the "data" field from the response
      const blogs = response.data.data;

      // Sort blogs by updated_at in descending order
      const sortedBlogs = blogs.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );

      setBlogs(sortedBlogs);
    } catch (error) {
      console.error("Error fetching blogs", error);
    } finally {
      setLoading(false);
    }
  };

  // Call fetchBlogs inside useEffect
  fetchBlogs();

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  // Function to paginate blogs
  const paginateBlogs = () => {
    const startIndex = (currentPage - 1) * blogsPerPage;
    const endIndex = startIndex + blogsPerPage;
    return filteredBlogs.slice(startIndex, endIndex);
  };

  // Filtered blogs based on search query
  const filteredBlogs = blogs.filter((blog) =>
    blog.title.toLowerCase().includes(search.toLowerCase())
  );

  // Pagination controls
  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

  // Function to format the date to "01 Agustus 2023"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("id-ID", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  // Generate pagination numbers
  const renderPagination = () => {
    const pages = [];

    if (currentPage > 1) {
      pages.push(
        <button
          key={currentPage - 1}
          onClick={() => setCurrentPage(currentPage - 1)}
          className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white"
        >
          {currentPage - 1}
        </button>
      );
    }

    pages.push(
      <button
        key={currentPage}
        onClick={() => setCurrentPage(currentPage)}
        className="mx-1 px-3 py-1 rounded bg-blue-500 text-white"
      >
        {currentPage}
      </button>
    );

    if (currentPage < totalPages) {
      pages.push(
        <button
          key={currentPage + 1}
          onClick={() => setCurrentPage(currentPage + 1)}
          className="mx-1 px-3 py-1 rounded bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white"
        >
          {currentPage + 1}
        </button>
      );
    }

    return pages;
  };

  return (
    <>
      {" "}
      <Helmet>
        <title>Convogenius | Blog</title>
        <meta
          name="description"
          content="Explore the latest insights and updates on branding, sales applications, and AI technologies on the Convogenius blog. Stay informed with our expert articles."
        />
        <meta
          name="keywords"
          content="Convogenius, blog, branding, sales applications, AI, technology insights, expert articles"
        />
        <meta name="author" content="Convogenius Team" />
        <meta property="og:title" content="Convogenius | Blog" />
        <meta
          property="og:description"
          content="Explore the latest insights and updates on branding, sales applications, and AI technologies on the Convogenius blog. Stay informed with our expert articles."
        />
        <meta
          property="og:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <meta property="og:url" content="https://www.convogenius.my.id/blog" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Convogenius" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Convogenius | Blog" />
        <meta
          name="twitter:description"
          content="Explore the latest insights and updates on branding, sales applications, and AI technologies on the Convogenius blog. Stay informed with our expert articles."
        />
        <meta
          name="twitter:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <link rel="canonical" href="https://www.convogenius.my.id/blog" />
        {/* Google Tag Manager */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LR5JNC7FGT"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-LR5JNC7FGT');
        `}
        </script>
      </Helmet>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold ">What's On Convo</h1>
        <p className="text-gray-500 mb-4">
          Our stories, latest updates, and exclusive promos. Find anything you
          want to know about us.
        </p>
        <div className="flex items-center mb-4">
          <input
            type="text"
            placeholder="Search by title"
            value={search}
            onChange={handleSearchChange}
            className="border rounded p-2 w-full"
          />
        </div>

        {/* Blog Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {loading
            ? Array.from({ length: 8 }).map((_, index) => (
                <SkeletonCard key={index} />
              ))
            : paginateBlogs().map((blog) => (
                <div
                  key={blog.id}
                  className="relative border border-blue-600 rounded p-4 shadow-md hover:bg-gray-100  group"
                >
                  {/* <div className="absolute top-2 right-2 opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100 transition-all duration-300 transform translate-y-2 group-hover:translate-y-0 ease-out">
                  <ArrowUpCircleIcon className="h-5 w-5 text-blue-500 cursor-pointer" />
                </div> */}
                  <Link to={`/detail_blog/${blog.slug}`} className="block">
                    <h2 className="text-xl font-semibold mb-2">
                      {truncateText(blog.title, 80)}
                    </h2>
                    <p className="text-gray-600 mb-2">
                      {truncateText(blog.description, 70)}
                    </p>
                    <p className="text-gray-400 text-sm">
                      {formatDate(blog.updated_at)}
                    </p>
                  </Link>
                </div>
              ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center mt-4">{renderPagination()}</div>
      </div>
    </>
  );
};

export default BlogPage;
