import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";

import ActionButtons from "../../components/Account/ProfileEdit/ActionButtons";
import BannerPicture from "../../components/Account/ProfileEdit/BannerPicture";
import EmailField from "../../components/Account/ProfileEdit/EmailField";
import ProfilePicture from "../../components/Account/ProfileEdit/ProfilePicture";
import UsernameField from "../../components/Account/ProfileEdit/UsernameField";

const ProfileEdit = ({ userDetails = {} }) => {
  const [username, setUsername] = useState(userDetails.username || "");
  const [email, setEmail] = useState(userDetails.email || "");
  const [profilePicture, setProfilePicture] = useState("");
  const [bannerPicture, setBannerPicture] = useState("");
  const [newUsername, setNewUsername] = useState(userDetails.username || "");
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const [newBannerPicture, setNewBannerPicture] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  const fetchData = async () => {
    setIsLoading(true); 
    const token = localStorage.getItem("authToken");
    const loggedInEmail = localStorage.getItem("userEmail");
  
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/${loggedInEmail}/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const result = await response.json();
      if (result.status === 200 && result.data) {
        const user = result.data;

        setUsername(user.Username || "");
        setEmail(user.Email || "");
        setProfilePicture(
          user.ProfileImage
            ? `data:image/png;base64,${user.ProfileImage}`
            : ""  
        );
        setBannerPicture(
          user.ProfileBanner
            ? `data:image/png;base64,${user.ProfileBanner}`
            : ""
        );
      } else {
        console.error("Error fetching user data:", result.message);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error(`Error fetching user data: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  useEffect(() => {
    fetchData();
  }, []);

  const handleSaveClick = async () => {
    const token = localStorage.getItem("authToken");
    const loggedInEmail = localStorage.getItem("userEmail");

    try {
      let success = false;
      const promises = [];

      if (newProfilePicture) {
        const profileFormData = new FormData();
        profileFormData.append("profile_image", newProfilePicture);

        const profilePromise = fetch(
          `${apiUrl}/api/v1/${loggedInEmail}/upload-profile-image`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: profileFormData,
          }
        ).then((response) => {
          if (!response.ok) throw new Error("Failed to upload profile picture");
          return response.json();
        });

        promises.push(profilePromise);
      }

      if (newBannerPicture) {
        const bannerFormData = new FormData();
        bannerFormData.append("banner_image", newBannerPicture);

        const bannerPromise = fetch(
          `${apiUrl}/api/v1/${loggedInEmail}/upload-profile-banner-image`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: bannerFormData,
          }
        ).then((response) => {
          if (!response.ok) throw new Error("Failed to upload banner picture");
          return response.json();
        });

        promises.push(bannerPromise);
      }

      if (isEditing && newUsername !== username) {
        const usernamePromise = fetch(
          `${apiUrl}/api/v1/${loggedInEmail}/edit-username`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ username: newUsername }),
          }
        ).then((response) => {
          if (!response.ok) throw new Error("Failed to update username");
          return response.json();
        });

        promises.push(usernamePromise);
      }

      const results = await Promise.all(promises);
      success = true;

      if (results.length > 0) {
        toast.success("Account successfully updated!");
        setTimeout(() => {
          window.location.href = "/account";
        }, 1000);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-6 flex flex-col items-center">
    <Helmet>
      <title>Edit Profile | Convogenius</title>
    </Helmet>
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-4xl">
      <h2 className="text-2xl font-semibold mb-4 text-center">Edit Profile</h2>

      {isLoading ? (
        <div className="mb-4">
          <Skeleton height={200} />
        </div>
      ) : (
        <BannerPicture
          bannerPicture={bannerPicture}
          setBannerPicture={setBannerPicture}
          setNewBannerPicture={setNewBannerPicture}
        />
      )}

      {isLoading ? (
        <div className="mb-4">
          <Skeleton circle={true} height={100} width={100} />
        </div>
      ) : (
        <ProfilePicture
          profilePicture={profilePicture}
          setProfilePicture={setProfilePicture}
          setNewProfilePicture={setNewProfilePicture}
        />
      )}

    {isLoading ? (
      <div className="mb-4">
        <Skeleton height={50} width={280} /> 
      </div>
    ) : (
      <UsernameField
        newUsername={newUsername}
        setNewUsername={setNewUsername}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    )}

{isLoading ? (
      <div className="mb-4">
        <Skeleton height={50} width={280} /> 
      </div>
    ) : (
      <EmailField email={email} />
    )}
      <ActionButtons handleSaveClick={handleSaveClick} />
    </div>
  </div>
  );
};

export default ProfileEdit;
