import { ArrowDownTrayIcon, PlusIcon } from "@heroicons/react/24/solid";
import React from "react";

const ActionButtons = ({ handleCreate, handleDownloadPDF }) => {
  return (
    <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2">
      <button
        onClick={handleCreate}
        className="bg-blue-600 text-white px-4 py-2 rounded-2xl flex items-center hover:bg-blue-700"
      >
        <PlusIcon className="h-5 w-5 mr-2" /> Create Data
      </button>
      <button
        onClick={handleDownloadPDF}
        className="bg-white border border-gray-800 text-gray-800 px-4 py-2 rounded-2xl flex items-center hover:bg-blue-700 hover:text-white hover:border-blue-700 transition-colors"
      >
        <ArrowDownTrayIcon className="h-5 w-5 mr-2" /> Download PDF
      </button>
    </div>
  );
};

export default ActionButtons;
