import { Analytics } from "@vercel/analytics/react";
import React, { useState } from "react";

import BlogSection from "../../components/home/blog/BlogSection";
import GetStartedSection from "../../components/home/GetStarted/GetStarted";
import Helmet from "../../components/home/helmet/helmet";
import MainSection from "../../components/home/MainSection/MainSection";
import TimelineSection from "../../components/home/MainSection/TimelineSection";
import WhyChooseSection from "../../components/home/MainSection/WhyChooseSection";
import { default as ImageModal, default as InfoModal } from "../../components/InfoModal/InfoModalHome";

const HomePage = () => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(true); // Set default true agar langsung tampil

  const closeInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  return (
    <>
      <Helmet />
      <Analytics />
      <MainSection />
      <WhyChooseSection />
      <TimelineSection />
      <BlogSection />
      <GetStartedSection />

      <InfoModal />
      <ImageModal isOpen={isInfoModalOpen} onClose={closeInfoModal} />
    </>
  );
};

export default HomePage;
