// RoomChatPage.js
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ActionButtons from "../../components/RoomChat/ActionButtons";
import ChatHistoryTable from "../../components/RoomChat/ChatHistoryTable";
import Pagination from "../../components/RoomChat/Pagination";
import SearchBar from "../../components/RoomChat/SearchBar";
import "../../index.css";

const RoomChatPage = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);
  const [searchInput, setSearchInput] = useState("");
  const [filteredChatHistory, setFilteredChatHistory] = useState([]);
  const [animationDirection, setAnimationDirection] = useState("next");
  const navigate = useNavigate();
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  useEffect(() => {
    const fetchChatHistory = async () => {
      const userEmail = localStorage.getItem("userEmail");
      if (!userEmail) {
        console.error("User email not found in local storage");
        return;
      }

      try {
        const response = await fetch(
          `${apiUrl}/api/v1/chat-history/${userEmail}`
        );
        if (response.ok) {
          const result = await response.json();
          const data = result.data;

          if (Array.isArray(data)) {
            setChatHistory(data);
            setFilteredChatHistory(data);
          } else {
            console.error("Fetched data is not an array");
          }
        } else {
          console.error("Failed to fetch chat history");
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChatHistory();
  }, []);

  useEffect(() => {
    const filteredData = chatHistory.filter((chat) =>
      chat.title.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredChatHistory(filteredData);
    setCurrentPage(1);
  }, [searchInput, chatHistory]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleDelete = async (chatId) => {
    const userEmail = localStorage.getItem("userEmail");
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/chat-history/${userEmail}/${chatId}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        setChatHistory((prevChatHistory) =>
          prevChatHistory.filter((chat) => chat.id !== chatId)
        );
        toast.success("Chat history deleted successfully!");
      } else {
        toast.error("Failed to delete chat history.");
      }
    } catch (error) {
      console.error("Error deleting chat history:", error);
      toast.error("Error deleting chat history.");
    }
  };

  const handleDeleteClick = (chatId) => {
    setLoading((prevLoading) => ({ ...prevLoading, [chatId]: true }));
    handleDelete(chatId).then(() => {
      setLoading((prevLoading) => ({ ...prevLoading, [chatId]: false }));
    });
  };

  const handleView = (chatId) => {
    navigate(`/cht-ai/${chatId}`);
  };

  const handleDeleteAll = async () => {
    const userEmail = localStorage.getItem("userEmail");
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/chat-history/${userEmail}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        setChatHistory([]);
        toast.success("All chat histories deleted successfully!");
      } else {
        toast.error("Failed to delete all chat histories.");
      }
    } catch (error) {
      console.error("Error deleting all chat histories:", error);
      toast.error("Error deleting all chat histories.");
    }
  };

  const totalPages = Math.ceil(filteredChatHistory.length / itemsPerPage);
  const currentItems = filteredChatHistory.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleClick = (pageNumber) => {
    setAnimationDirection(currentPage < pageNumber ? "next" : "prev");
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Helmet>
        <title>Room Chat | Convogenius</title>
      </Helmet>
      <ToastContainer />
      <div className="container mx-auto mt-10">
        <h1 className="text-2xl font-semibold mb-4">Chat History</h1>
        <SearchBar
          searchInput={searchInput}
          handleSearchChange={handleSearchChange}
        />
        <ActionButtons handleDeleteAll={handleDeleteAll} />
        <ChatHistoryTable
          isLoading={isLoading}
          currentItems={currentItems}
          animationDirection={animationDirection}
          handleView={handleView}
          handleDeleteClick={handleDeleteClick}
          loading={loading}
        />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleClick={handleClick}
        />
      </div>
    </>
  );
};

export default RoomChatPage;
