import React, { useEffect, useState } from "react";

const DashboardHeader = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null); // To capture any errors
  const [isImageLoaded, setIsImageLoaded] = useState(false); // Track image load state

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    setUserEmail(email);

    // Fetch user info from backend
    if (email) {
      fetchUserInfo(email);
    }
  }, []); // Add empty dependency array to run once on mount

  const fetchUserInfo = () => {
    const token = localStorage.getItem("authToken");
    const email = localStorage.getItem("userEmail");
    const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
    const apiUrl = atob(encodedUrl);
    const endpoint = `${apiUrl}/api/v1/dashboard`;

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Email": email,
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUserInfo(data.user);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <section className="w-full bg-blue-600 p-6 rounded-3xl mb-10 text-white flex flex-col lg:flex-row items-center lg:items-center">
      <div className="lg:w-1/2 mb-4 lg:mb-0 lg:mr-4 flex flex-col justify-center text-center lg:text-left">
        <h1 className="text-2xl lg:text-3xl font-bold mb-2 animate-slide-in-left">
          Welcome to Your Dashboard{" "}
          <span className="text-blue-600 bg-white px-3 py-1 rounded-xl mb-2 mt-2 block lg:inline-block">
            {userInfo?.Username || userEmail}
          </span>
        </h1>
        <p className="text-sm lg:text-base animate-slide-in-left">
          Here you can find the latest trends in social media and keep track of
          your sales data. Use the sections below to explore trending topics on
          YouTube, TikTok, and Twitter, and to manage your sales performance.
        </p>
      </div>
      <div className="lg:w-1/2 w-full h-auto object-cover rounded-lg animate-slide-in-right mt-4 lg:mt-0 relative">
        {!isImageLoaded && (
          <div className="absolute top-0 left-0 w-full h-full bg-gray-300 rounded-lg animate-pulse"></div>
        )}
        <img
          src="https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/dashboard_user/hello-user.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9kYXNoYm9hcmRfdXNlci9oZWxsby11c2VyLnBuZyIsImlhdCI6MTczNjIxODYzNSwiZXhwIjoxNzY3NzU0NjM1fQ.kzcS-UF-qTfqRMb8RFG3WanHWx4S2V_hr3YPxtJG7ZY&t=2025-01-07T02%3A57%3A14.122Z"
          alt="Dashboard Introduction"
          className={`rounded-lg ${!isImageLoaded ? "hidden" : ""}`}
          onLoad={handleImageLoad}
        />
      </div>
    </section>
  );  
};

export default DashboardHeader;