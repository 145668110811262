import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageHeader from "../../components/AI/ImageAi/ImageHeader";
import Message from "../../components/AI/ImageAi/Message";
import MessageList from "../../components/AI/ImageAi/MessageList";
import PromptCard from "../../components/AI/ImageAi/PromptCard";
import PromptSelector from "../../components/AI/ImageAi/PromptSelector";
import SkeletonLoader from "../../components/AI/ImageAi/SkeletonLoader";

const ImagePage = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [responseStarted, setResponseStarted] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");
    setUserEmail(userEmail);

    fetchUserInfo(userEmail);
  }, []);

  const fetchUserInfo = (email) => {
    const token = localStorage.getItem("authToken");
    const endpoint = `${apiUrl}/api/v1/dashboard`;

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Email": email,
      },
      body: JSON.stringify({ email })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUserInfo(data.user);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    const message = {
      id: messages.length + 1,
      text: newMessage,
      type: "text",
      sender: "user",
      timestamp: new Date().toISOString(),
    };

    setMessages([...messages, message]);
    setNewMessage("");
    setLoading(true);
    setTimeElapsed(0);
    setResponseStarted(false);
    setShowPrompts(false);

    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 100);

    queryImage({ inputs: newMessage })
      .then((base64Image) => {
        const responseMessage = {
          id: messages.length + 2,
          text: base64Image,
          type: "image",
          sender: "bot",
          timestamp: new Date().toISOString(),
          prompt: newMessage,
        };
        setMessages((prevMessages) => [...prevMessages, responseMessage]);
        clearInterval(timer);
        setLoading(false);
        setResponseStarted(false);
      })
      .catch((error) => {
        setError(error.message);
        clearInterval(timer);
        setLoading(false);
      });
  };

  const queryImage = async (data) => {
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/generate-image-ai/${userEmail}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      // Assuming the image base64 code is stored in the first element of the data array
      const base64Image = result.data[0];

      return `data:image/jpeg;base64,${base64Image}`;
    } catch (error) {
      // Display the error message in a Toastify notification
      toast.error(`Error: ${error.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw error; // Optionally re-throw the error if you need further handling
    }
  };

  const handlePromptClick = (prompt) => {
    const message = {
      id: messages.length + 1,
      text: prompt,
      type: "text",
      sender: "user",
      timestamp: new Date().toISOString(),
    };

    setMessages([...messages, message]);
    setLoading(true);
    setTimeElapsed(0);
    setResponseStarted(false);
    setShowPrompts(false);

    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 100);

    queryImage({ inputs: prompt })
      .then((base64Image) => {
        const responseMessage = {
          id: messages.length + 2,
          text: base64Image,
          type: "image",
          sender: "bot",
          timestamp: new Date().toISOString(),
          prompt: prompt,
        };
        setMessages((prevMessages) => [...prevMessages, responseMessage]);
        clearInterval(timer);
        setLoading(false);
        setResponseStarted(false);
      })
      .catch((error) => {
        setError(error.message);
        clearInterval(timer);
        setLoading(false);
      });
  };

  const handleClearMessage = (id) => {
    setMessages(messages.filter((message) => message.id !== id));
  };

  const handleDownload = (imageData, fileName) => {
    const link = document.createElement("a");
    link.href = imageData;
    link.download = `${fileName}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex flex-col h-screen bg-white text-gray-800">
      <Helmet>
        <title>Branding Image With AI | {userEmail}</title>
      </Helmet>
      <ImageHeader />
      {showPrompts && <PromptSelector onPromptClick={handlePromptClick} />}{" "}
      <main className="flex-1 p-4 overflow-y-auto">
        {messages.map((message) => (
          <Message
            key={message.id}
            message={message}
            onClear={() => handleClearMessage(message.id)}
            onDownload={() =>
              handleDownload(
                message.text,
                message.prompt || `image-${message.id}`
              )
            }
          />
        ))}
        {loading && !responseStarted && <SkeletonLoader />}{" "}
      </main>
      <form
        onSubmit={handleSendMessage}
        className="flex justify-center items-center py-2 px-4 lg:py-2"
      >
        <div className="flex items-center max-w-2xl w-full">
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            rows={1} // Starting with a single row; it will expand as needed
            className="flex-1 px-4 py-2 bg-white rounded-lg focus:outline-none focus:bg-gray-200 text-gray-800 border border-gray-300 resize-none overflow-y-auto shadow-md"
            style={{
              maxHeight: "200px", // Optional: max height limit for scrolling
              paddingRight: "2.5rem", // Adjust for aesthetics
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevent the default new line behavior
                handleSendMessage(e); // Call the function to send the message
              }
            }}
          />
          <button
            type="submit"
            className="ml-2 bg-blue-600 text-white p-3 rounded-full hover:bg-blue-700 focus:bg-blue-700 focus:outline-none"
          >
            {loading ? (
              <span className="text-sm">{(timeElapsed / 10).toFixed(1)}s</span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
              </svg>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const ImageAI = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [responseStarted, setResponseStarted] = useState(false);

  const handleClearMessage = (id) => {
    setMessages(messages.filter((message) => message.id !== id));
  };

  const handleDownload = (imageData, fileName) => {
    const link = document.createElement("a");
    link.href = imageData;
    link.download = `${fileName}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const prompts = ["Generate Cat Image", "Create Landscape", "AI Art"];

  return (
    <div className="p-6">
      <MessageList
        messages={messages}
        handleClearMessage={handleClearMessage}
        handleDownload={handleDownload}
      />

      {loading && !responseStarted && <SkeletonLoader />}

      <div className="flex space-x-2 mt-4">
        {prompts.map((prompt, index) => (
          <PromptCard
            key={index}
            text={prompt}
            onClick={() => console.log(`Prompt clicked: ${prompt}`)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImagePage;
