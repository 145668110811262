import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const ReportProblemPage = () => {
  const [formData, setFormData] = useState({
    type_of_problem: "",
    description: "",
    additional_information: "",
  });
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  const email = localStorage.getItem("userEmail"); // Fetch email from local storage

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/problem-report`,
        {
          email: email,
          type_of_problem: formData.type_of_problem,
          description: formData.description,
          additional_information: formData.additional_information,
        }
      );
      if (response.status === 200) {
        toast.success("Problem reported successfully!");
        setFormData({
          type_of_problem: "",
          description: "",
          additional_information: "",
        });
      }
    } catch (error) {
      toast.error("Error reporting the problem. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6 flex items-center justify-center">
      <Helmet>
        <title>Report Problem | Convogenius</title>
      </Helmet>
      <div className="max-w-3xl w-full bg-white p-8 rounded-lg shadow-lg lg:mb-0 mb-20">
        <h1 className="text-3xl font-semibold mb-6 text-gray-800">
          Report a Problem
        </h1>
        <p className="text-gray-500 mb-8">
          Please provide details about the issue you're facing. We will address
          it as soon as possible.
        </p>

        <form onSubmit={handleSubmit}>
          {/* Email Field */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <input
              type="text"
              value={email}
              readOnly
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm bg-gray-100"
            />
          </div>

          {/* Problem Type */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Type of Problem
            </label>
            <select
              name="type_of_problem"
              value={formData.type_of_problem}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="" disabled>
                Select a problem type
              </option>
              <option value="login">Login Issues</option>
              <option value="register">Register Problems</option>
              <option value="bug">Bug Report</option>
              <option value="other">Other</option>
            </select>
          </div>

          {/* Problem Description */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Description of the Problem
            </label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows="6"
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Please describe the issue in detail"
              required
            ></textarea>
          </div>

          {/* Additional Info */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Additional Information (Optional)
            </label>
            <textarea
              name="additional_information"
              value={formData.additional_information}
              onChange={handleChange}
              rows="4"
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Any additional information that might help us resolve the issue"
            ></textarea>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="py-3 px-6 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition duration-300"
          >
            Submit Report
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReportProblemPage;
