import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import CardNews from "../../components/all/CardNews/CardNews";
import SkeletonLoader from "../../components/TrendingYoutube/SkeletonLoader";

export default function AllLifestyleNews() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchNews(page);
  }, [page]);

  const fetchNews = async (currentPage) => {
    try {
      setIsFetchingMore(true);
      const userEmail = localStorage.getItem("userEmail");
      const response = await axios.post("https://convogenius.cloud/api/v1/news/lifestyle", {
        email: userEmail,
      });

      const articles = response.data.data;
      const startIndex = (currentPage - 1) * 10;
      const newArticles = articles.slice(startIndex, startIndex + 10);

      setNews((prev) => [...prev, ...newArticles]);
      setHasMore(newArticles.length > 0);
    } catch (error) {
      console.error("Error fetching lifestyle news:", error);
    } finally {
      setLoading(false);
      setIsFetchingMore(false);
    }
  };

  const observer = useRef();
  const lastNewsRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <div className="p-6 lg:mb-0 mb-20">
      <Helmet>
        <title>Convogenius | All Lifestyle News</title>
      </Helmet>

      <h2 className="lg:text-3xl text-xl text-gray-800 font-semibold mb-2 text-center">
        All Lifestyle News
      </h2>
      <p className="text-lg text-gray-600 text-center mb-2 mt-2">
        Stay updated with the latest lifestyle trends
      </p>

      <div className="p-6 rounded-lg w-full transition duration-300">
        <h3 className="lg:text-2xl text-xl text-gray-800 font-semibold mb-4">
          Trending Lifestyle News
        </h3>

        {news.length === 0 && loading ? (
          <SkeletonLoader count={8} />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {news.map((article, index) => (
              <CardNews
                key={index}
                article={article}
                index={index}
                lastNewsRef={news.length === index + 1 ? lastNewsRef : null}
              />
            ))}
          </div>
        )}

        {isFetchingMore && (
          <div className="flex justify-center items-center mt-4">
            <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}
      </div>
    </div>
  );
}
