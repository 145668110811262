export default function CardNews({ article, lastNewsRef }) {
    return (
      <div ref={lastNewsRef} className="bg-white shadow-md p-4 rounded-lg">
        <img
          src={article.thumbnail}
          alt={article.title}
          className="w-full h-40 object-cover rounded-md"
        />
        <h3 className="text-lg font-semibold mt-2">{article.title}</h3>
        <p className="text-gray-600 mt-2">{new Date(article.pubDate).toLocaleDateString()}</p>
        <a
          href={article.link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 mt-2 inline-block"
        >
          Read More
        </a>
      </div>
    );
  }
  