import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BookkeepingFeatures from "../../components/Dashboard/BookkeepingFeatures/Bookkeeping";
import DashboardHeader from "../../components/Dashboard/DashboardHeader/DashboardHeader";
import FeatureCards from "../../components/Dashboard/FeatureCards/FeatureCards";
import LifestyleNews from "../../components/Dashboard/LifestyleNews/LifestyleNews";
import YouTubeTrending from "../../components/Dashboard/YoutubeTrending/YouTubeTrending";
// import InfoModal from "../../components/InfoModal/InfoModal";
import "../../index.css";

const DashboardPage = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userInfo, setUserInfo] = useState(null);

  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(true); // Modal muncul saat pertama kali membuka halaman
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");
    setUserEmail(email);

    // Fetch user info from backend
    fetchUserInfo(email);
  });

  const fetchUserInfo = (email) => {
    const token = localStorage.getItem("authToken");
    const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
    const apiUrl = atob(encodedUrl);
    const endpoint = `${apiUrl}/api/v1/dashboard`;

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-Email": email,
      },
      body: JSON.stringify({ email })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUserInfo(data.user);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center p-4">
      <Helmet>
        <title>Dashboard | {userInfo?.Username || userEmail}</title>
      </Helmet>
      <div className="w-full max-w-6xl">
        <DashboardHeader />

        <h2 className="lg:text-3xl text-xl text-gray-800 font-semibold mb-2 text-center">
          Top 4 Trending Social Media
        </h2>
        <p className="text-lg text-gray-600 text-center mb-2 mt-2">
          Top Trending to help innovate your product
        </p>
        <YouTubeTrending />
        
        <LifestyleNews />

        <BookkeepingFeatures />

        <FeatureCards />

        {/* <InfoModal show={showModal} handleClose={handleCloseModal} /> */}
      </div>
    </div>
  );
};

export default DashboardPage;
