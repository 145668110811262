import { useEffect, useRef, useState } from "react";

const GetStartedSection = () => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.3 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`relative flex flex-col items-center justify-center py-12 px-6 sm:py-16 sm:px-8 mt-7 mb-7 overflow-hidden transition-all duration-1000 ${
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
    >
      <div className="absolute inset-0 bg-blue-500 opacity-80"></div>

      <h2 className="text-xl sm:text-3xl font-bold mb-4 sm:mb-6 relative text-white text-center z-10">
        Get Started with ConvoGenius
      </h2>

      <p className="text-white text-center mb-4 sm:mb-6 sm:text-lg z-10">
        Begin your journey with ConvoGenius and explore all the features designed to help you grow your business. 
        Whether you're starting small or scaling up, we're here to support you every step of the way.
      </p>

      <a href="/login">
        <button className="relative z-10 bg-white text-blue-500 font-semibold px-6 py-3 sm:px-8 sm:py-4 w-full max-w-sm rounded-full shadow-lg hover:bg-gray-200 transition">
          Let’s Go
        </button>
      </a>
    </div>
  );
};

export default GetStartedSection;
