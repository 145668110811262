import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const BlogSection = () => {
  const [blogs, setBlogs] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/v1/blogs/get-blogs`)
      .then((response) => {
        const blogs = response.data.data;
        const sortedBlogs = blogs.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setBlogs(sortedBlogs.slice(0, 3));
      })
      .catch((error) => console.error("Error fetching blogs:", error));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        setIsVisible(rect.top < window.innerHeight * 0.75);
      }
    };
    
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("id-ID", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <div 
      ref={sectionRef} 
      className={`container mx-auto px-4 md:px-8 mt-8 mb-32 transition-colors duration-700 ${isVisible ? "text-blue-600" : "text-gray-800"}`}
    >
      <div className="text-center mb-8">
        <h1 className={`text-4xl font-bold mb-2 transition-colors duration-700 ${isVisible ? "text-blue-600" : "text-gray-800"}`}>What's New</h1>
        <p className="text-lg text-gray-700 mb-4">
          Check out the latest announcements and updates from Convogenius.
        </p>
        <Link to="/blog">
          <button className="px-6 py-2 rounded-full transition-colors duration-300 border border-gray-600 hover:bg-blue-700 hover:text-white hover:border-blue-700">
            View All Blogs
          </button>
        </Link>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {blogs.map((blog) => (
          <Link key={blog.id} to={`/detail_blog/${blog.slug}`}>
            <div 
              className={`border rounded-lg shadow-lg p-6 transition-all duration-500 ${isVisible ? "border-blue-600" : "border-gray-600"} hover:shadow-xl hover:border-blue-700`}
            >
              <h2 className="text-xl font-bold mb-2">{truncateText(blog.title, 80)}</h2>
              <p className="text-gray-700 mb-4">{truncateText(blog.description, 70)}</p>
              <p className="text-gray-500 text-sm">{formatDate(blog.updated_at)}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogSection;
