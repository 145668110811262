import { useEffect, useRef, useState } from "react";

const WhyChooseSection = () => {
  const sectionsRef = useRef([]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let visibleIndex = null;
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            visibleIndex = sectionsRef.current.indexOf(entry.target);
          }
        });
        if (visibleIndex !== null) {
          setActiveIndex(visibleIndex);
        }
      },
      { threshold: 0.3, rootMargin: "0px 0px -20% 0px" }
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRef.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div className="container mx-auto px-6 lg:px-12 py-10 mt-10 mb-10">
      <h2 className="text-4xl font-bold text-gray-900 text-center mb-6">
        Why Choose Convogenius AI?
      </h2>

      <p className="text-lg text-gray-700 text-center mb-10">
        Convogenius AI offers a blend of advanced technology, efficiency, and versatility to empower your business and streamline content creation.
      </p>

      <div className="flex flex-col lg:flex-row items-center lg:items-start gap-10">
        {/* Image Section */}
        <div className="w-full lg:w-1/2 flex justify-center">
          <img
            src="https://dqfnhegcrjcxcxselwey.supabase.co/storage/v1/object/sign/image/WhyChooise.png?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJpbWFnZS9XaHlDaG9vaXNlLnBuZyIsImlhdCI6MTczODg0NDM5NCwiZXhwIjoxNzcwMzgwMzk0fQ.XmbhieXO3Vuvi38mjSn3FkScCO7VWPzxELBWYqPMXz4"
            alt="Why Choose Convogenius AI"
            className="rounded-lg shadow-lg w-full max-w-md"
          />
        </div>

        {/* Content Section */}
        <div className="w-full lg:w-1/2 space-y-6">
          {[
            {
              title: "Advanced Capabilities",
              text: "Convogenius AI is designed with cutting-edge technology to generate high-quality, coherent text and realistic images, making it a powerful tool for various applications.",
              borderColor: "border-blue-300",
            },
            {
              title: "Efficiency",
              text: "By automating the generation of text and images, Convogenius AI speeds up content creation processes, saving time and resources.",
              borderColor: "border-blue-400",
            },
            {
              title: "Versatility",
              text: "Convogenius AI can be applied across different domains such as marketing, entertainment, and education, offering tailored solutions.",
              borderColor: "border-blue-500",
            },
            {
              title: "Seamless Integration",
              text: "Convogenius AI can be easily integrated into various platforms and systems, ensuring a smooth and efficient user experience.",
              borderColor: "border-blue-600",
            }            
          ].map((item, index) => (
            <div
              key={index}
              ref={(el) => (sectionsRef.current[index] = el)}
              className={`p-6 bg-white shadow-md rounded-lg border-l-4 transition-all duration-500 ${
                activeIndex === index ? `${item.borderColor} scale-105` : "border-gray-300"
              } ${index === 1 ? "mt-10 mb-10" : ""}`}
            >
              <h1 className="text-2xl font-bold mb-2 text-gray-800">
                {item.title}
              </h1>
              <p className="text-base text-gray-800">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseSection;
