import { useEffect, useRef, useState } from "react";

const TimelineSection = () => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.3 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className="text-center mt-32 pb-32 bg-gradient-to-b transition-colors duration-700"
    >
      <h2
        className={`text-4xl font-bold mb-6 transition-colors duration-700 ${
          isVisible ? "text-blue-600" : "text-gray-800"
        }`}
      >
        Convogenius Journey
      </h2>

      <p
        className={`text-lg mb-12 transition-colors duration-700 ${
          isVisible ? "text-gray-600" : "text-gray-700"
        }`}
      >
        Join us as we reflect on the milestones and achievements of Convogenius,
        showcasing our journey from concept to a powerful AI platform.
      </p>

      <div className="relative max-w-6xl mx-auto px-6 md:px-12">
        <h2
          className={`text-3xl font-bold mb-6 transition-colors duration-700 ${
            isVisible ? "text-blue-600 hover:text-blue-400" : "text-gray-800"
          }`}
          aria-label="Timeline Events"
        >
          Timeline of Convogenius AI
        </h2>

        <div className="flex flex-col md:flex-row items-center gap-y-6 md:gap-x-6 overflow-x-auto md:overflow-visible scrollbar-hide snap-x snap-mandatory">
          {["January 2024", "May 2024", "August 2024", "October 2024"].map(
            (title, index) => (
              <div
                key={index}
                className={`shadow-lg rounded-lg p-6 w-full md:w-64 h-64 text-left relative flex-shrink-0 snap-center transition-all duration-700 border ${
                  isVisible ? "border-blue-600 bg-white" : "border-gray-400 bg-gray-100"
                }`}
              >
                <h3
                  className={`text-xl font-semibold mb-2 transition-colors duration-700 ${
                    isVisible ? "text-blue-600" : "text-gray-800"
                  }`}
                  aria-label={`${title} Overview`}
                >
                  {title}
                </h3>
                <p
                  className={`transition-colors duration-700 ${
                    isVisible ? "text-gray-700" : "text-gray-900"
                  }`}
                  aria-label={`Description for ${title}`}
                >
                  {title === "January 2024" &&
                    "The idea of Convogenius AI sparked, focusing on using AI tools to transform product branding through text, image, and music generation."}
                  {title === "May 2024" &&
                    "Development officially began with a focused team, working towards bringing the platform to life."}
                  {title === "August 2024" &&
                    "Convogenius launched, receiving great feedback that encouraged continuous improvements."}
                  {title === "October 2024" &&
                    "Convogenius reached 20,000 users, helping businesses enhance branding, optimize operations, and track trends."}
                </p>

                {index !== 3 && (
                  <div className="hidden md:block absolute left-full h-1 w-6 bg-blue-500 top-1/2 transform -translate-y-1/2"></div>
                )}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default TimelineSection;
