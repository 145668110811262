import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ChartComponent from "../../components/Bookkeeping/BookkeepingComparison/ChartComponent";
import ModalComponent from "../../components/Bookkeeping/BookkeepingComparison/ModalComponent";
import TableComponent from "../../components/Bookkeeping/BookkeepingComparison/TableComponent";

const BookkeepingComparison = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [modalContent, setModalContent] = useState({
    image: "https://via.placeholder.com/150",
    title: "Welcome to Bookkeeping Comparison",
    description: "Explore your records and analyze the data.",
  });
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  useEffect(() => {
    const fetchData = async () => {
      const email = localStorage.getItem("userEmail");
      if (!email) return setLoading(false);

      try {
        const response = await fetch(
          `${apiUrl}/api/v1/monthyear/${email}`
        );
        const result = await response.json();

        if (result.status !== 200 || !Array.isArray(result.data))
          throw new Error();

        const monthYears = result.data.sort(
          (a, b) =>
            new Date(a.month_year + "-01") - new Date(b.month_year + "-01")
        );

        const labels = monthYears.map((my) => my.month_year);
        const totalPrices = monthYears.map((my) =>
          my.records.reduce((sum, record) => sum + (record.total_price || 0), 0)
        );

        // Calculate comparison between the latest two months
        const currentMonthTotal = totalPrices[totalPrices.length - 1] || 0;
        const lastMonthTotal = totalPrices[totalPrices.length - 2] || 0;

        let newModalContent;

        if (currentMonthTotal > lastMonthTotal) {
          newModalContent = {
            image: "/images/comparison/happy.png",
            title: "Congratulations! More Data Added",
            description:
              "Your data has increased compared to the previous month.",
          };
        } else if (currentMonthTotal < lastMonthTotal) {
          newModalContent = {
            image: "/images/comparison/angry.png",
            title: "Warning! Data Decreased",
            description:
              "Your data has decreased compared to the previous month.",
          };
        } else {
          newModalContent = {
            image: "/images/comparison/strategy.png",
            title: "No Improvement Detected",
            description:
              "There is no improvement in your data, let's create a strategy.",
          };
        }

        setModalContent(newModalContent);

        setChartData({
          labels,
          datasets: [
            {
              label: "Total Price",
              data: totalPrices,
              backgroundColor: "rgba(0, 0, 255, 0.2)",
              borderColor: "rgba(0, 0, 255, 1)",
              borderWidth: 1,
            },
          ],
        });

        setTableData(
          monthYears.map((my) => ({
            month_year: my.month_year,
            total_price: my.records.reduce(
              (sum, record) => sum + (record.total_price || 0),
              0
            ),
          }))
        );

        setLoading(false);
      } catch {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <Helmet>
        <title>Bookkeeping Comparison | Convogenius</title>
      </Helmet>
      <h1 className="text-3xl font-bold text-center mb-8">
        Bookkeeping Comparison
      </h1>
      {loading ? (
        <div className="space-y-4">
          <Skeleton height={40} />
          <Skeleton height={300} />
          <Skeleton count={5} height={40} />
        </div>
      ) : (
        <>
          <ChartComponent chartData={chartData} />
          <TableComponent loading={loading} tableData={tableData} />
        </>
      )}
      <ModalComponent
        isOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        modalImage={modalContent.image}
        modalTitle={modalContent.title}
        modalDescription={modalContent.description}
        loading={loading}
      />
    </div>
  );
};

export default BookkeepingComparison;
