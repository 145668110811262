import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const QuestionnairePage = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState({
    Q1: "",
    Q2: "",
    Q3: "",
    Q4: "",
    Q5: "",
    Q6: "",
    Q7: "",
    Q8: "",
    Q9: "",
    Q10: "",
  });
  const [susScore, setSusScore] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptedRules, setAcceptedRules] = useState(false); // New state for rules
  const email = localStorage.getItem("userEmail");
  const encodedUrl = "aHR0cHM6Ly9jb252b2dlbml1cy5jbG91ZC8=";
  const apiUrl = atob(encodedUrl);

  const questions = [
    {
      question: "I think that I would like to use this system frequently.",
      name: "Q1",
    },
    { question: "I found the system unnecessarily complex.", name: "Q2" },
    { question: "I thought the system was easy to use.", name: "Q3" },
    {
      question: "I think that I would need the support of a technical person.",
      name: "Q4",
    },
    {
      question:
        "I found the various functions in this system were well integrated.",
      name: "Q5",
    },
    {
      question: "I thought there was too much inconsistency in this system.",
      name: "Q6",
    },
    {
      question:
        "I would imagine that most people would learn this system quickly.",
      name: "Q7",
    },
    { question: "I found the system very cumbersome to use.", name: "Q8" },
    { question: "I felt very confident using the system.", name: "Q9" },
    {
      question: "I needed to learn many things before getting started.",
      name: "Q10",
    },
  ];

  const handleChange = (e) => {
    const { value } = e.target;
    setResponses((prev) => ({
      ...prev,
      [questions[currentQuestion].name]: value,
    }));
  };

  const handleNext = () => {
    if (!responses[questions[currentQuestion].name]) {
      toast.warning("Please select an answer before proceeding.");
      return;
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };
  const handleSubmit = async () => {
    if (Object.values(responses).some((response) => response === "")) {
      toast.warning("Please answer all questions before submitting.");
      return;
    }
    const formattedResponses = Object.fromEntries(
      Object.entries(responses).map(([key, value]) => [key, parseInt(value)])
    );

    setLoading(true); // Start loading indicator
    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/questionnaire/${email}`,
        formattedResponses
      );
      toast.success("Questionnaire successfully submitted!");
      setSusScore(response.data.data.sus_score);
      setSubmitted(true);
      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 10000);
    } catch (error) {
      console.error("Error submitting response:", error);
      toast.error("Failed to send answers, please try again.");
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  const handleAcceptRules = () => setAcceptedRules(true);

  if (!acceptedRules) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
        <Helmet>
          <title>Convogenius | Questionnaire</title>
        </Helmet>

        <div className="max-w-2xl bg-white rounded-lg shadow-lg p-8 lg:mb-0 mb-20">
          <h1 className="text-2xl font-bold mb-4">Introduction to SUS</h1>
          <p className="text-lg mb-4">
            <strong>System Usability Scale (SUS)</strong> is a method used to
            measure the usability level of a system. Created by John Brooke in
            1986, this method has been used to evaluate various products,
            including software, mobile applications, and websites.
          </p>
          <h2 className="text-xl font-semibold mb-2">Advantages of SUS</h2>
          <ul className="list-disc pl-6 mb-4">
            <li>Easy to use and well-received by respondents.</li>
            <li>Suitable for small sample studies with accurate results.</li>
            <li>Proven to be valid for determining system usability.</li>
          </ul>
          <p className="text-lg mb-4">SUS uses a Likert scale from 1 to 5:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>1 - Strongly Disagree</li>
            <li>2 - Disagree</li>
            <li>3 - Neutral</li>
            <li>4 - Agree</li>
            <li>5 - Strongly Agree</li>
          </ul>

          <h2 className="text-xl font-semibold mb-2">How SUS is Calculated</h2>
          <p className="mb-4">
            For each odd-numbered question, subtract 1 from the score. For each
            even-numbered question, subtract the score from 5. Sum all the
            values and multiply by 2.5 to get the final SUS score.
          </p>
          <p className="mb-4">
            The average SUS score is 68. If your score is below 68, it indicates
            that there may be usability issues with the system.
          </p>

          <button
            onClick={handleAcceptRules}
            className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition"
          >
            Start Questionnaire
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-6">
      <Helmet>
        <title>Convogenius | Questionnaire</title>
      </Helmet>

      <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-8">
        {submitted ? (
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">Your SUS Score</h1>
            <p className="text-xl">SUS Score: {susScore}</p>
            <p className="text-sm text-gray-500 mt-4">
              You will be redirected shortly...
            </p>
          </div>
        ) : (
          <>
            <h1 className="text-2xl font-bold text-center mb-6">
              Questionnaire
            </h1>
            <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
              <div className="p-6 bg-gray-50 rounded-md shadow-sm">
                <label className="block font-medium text-lg mb-4">
                  {currentQuestion + 1}. {questions[currentQuestion].question}
                </label>
                <div className="space-y-3">
                  {[1, 2, 3, 4, 5].map((option) => (
                    <label key={option} className="flex items-center space-x-3">
                      <input
                        type="radio"
                        name={questions[currentQuestion].name}
                        value={option}
                        checked={
                          responses[questions[currentQuestion].name] ===
                          option.toString()
                        }
                        onChange={handleChange}
                        className="h-5 w-5 text-blue-600"
                      />
                      <span className="text-sm">
                        {option}{" "}
                        {
                          [
                            "Strongly Disagree",
                            "Disagree",
                            "Neutral",
                            "Agree",
                            "Strongly Agree",
                          ][option - 1]
                        }
                      </span>
                    </label>
                  ))}
                </div>
              </div>

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={handleNext}
                  disabled={loading} // Disable button while loading
                  className={`px-6 py-2 rounded-md transition ${
                    loading ? "bg-gray-400" : "bg-blue-600 hover:bg-blue-700"
                  } text-white`}
                >
                  {loading
                    ? "Loading..."
                    : currentQuestion < questions.length - 1
                    ? "Next"
                    : "Submit"}
                </button>
              </div>
            </form>
          </>
        )}

        <ToastContainer />
      </div>
    </div>
  );
};

export default QuestionnairePage;
